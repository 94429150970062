<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="환경부 등록자료 구분목록"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrowMst" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="저장" 
            icon="save" 
            @beforeAction="saveMst"
            @btnCallback="saveCallback"/>
          <c-btn label="검색" icon="search"  @btnClicked="getList"/>
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'env-gov-data-mst',
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: '',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.govdata.mst.list.url;
      this.saveUrl = transactionConfig.env.air.govdata.mst.save.url;
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('ENV_GOV_DATA_TYPE_CD').then(_result1 => {
        this.$comm.getComboItems('ENV_GOV_DATA_UNIT_CD').then(_result2 => {
          this.grid.columns = [
            {
              required: true,
              name: 'plantCd',
              field: 'plantCd',
              label: '사업장',
              style: 'width:200px',
              align: 'left',
              type: 'plant',
              sortable: false,
            },
            {
              required: true,
              name: 'envGovDataTypeCd',
              field: 'envGovDataTypeCd',
              label: '환경부 등록자료 구분',
              align: 'center',
              style: 'width:250px',
              sortable: false,
              type: 'select',
              comboItems: _result1
            },
            {
              name: 'wasteFlag',
              field: 'wasteFlag',
              label: '폐기물원단위분석 대상',
              align: 'center',
              style: 'width:150px',
              type: 'check',
              true: 'Y',
              false: 'N',
              disableTarget: 'envGovDataTypeCd',
              disableCon: 'EGDTC00004',
              sortable: false,
            },
            {
              required: true,
              name: 'envGovDataTypeName',
              field: 'envGovDataTypeName',
              label: '환경부 등록자료 구분명',
              align: 'left',
              sortable: false,
              type: 'text',
            },
            {
              required: true,
              name: 'envGovDataUnitCd',
              field: 'envGovDataUnitCd',
              label: '단위',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'select',
              comboItems: _result2
            },
            {
              name: 'deptCd',
              field: 'deptCd',
              label: '관리부서',
              align: 'center',
              style: 'width:300px',
              sortable: false,
              type: 'deptMulti',
              deptCd: 'deptCd',
              isFirstValue: false,
            },
            {
              required: true,
              name: 'sortOrder',
              field: 'sortOrder',
              label: '순번',
              align: 'center',
              sortable: false,
              style: 'width:80px',
              type: 'number',
            },
            {
              name: 'useFlag',
              field: 'useFlag',
              label: '사용여부',
              align: 'center',
              style: 'width:120px',
              type: 'check',
              true: 'Y',
              false: 'N',
              sortable: false,
            },
          ]
          this.getList();
        });
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },  
    addrowMst() {
      this.grid.data.push({
        envGovDataMstId: uid(),  // 환경부자료요청 구분마스터 일련번호
        plantCd: null,  // 사업장코드
        envGovDataTypeCd: '',  // 환경부자료요청 구분코드
        envGovDataTypeName: '',  // 환경부자료요청 구분명
        envGovDataUnitCd: '',  // 단위코드
        useFlag: 'Y',  // 사용여부
        wasteFlag: 'N',
        deptCd: '',  // 관리부서
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    saveMst() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', 
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
